import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, IconButton, Toolbar, Container, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '../../icons/Menu';
import NavSectionTop from '../NavSectionTop';
import Logo from '../Logo';
import AccountPopover from './AccountPopover';
import { sections, useDashboardSections } from './DashboardLayout'

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: '#1b2e50',
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: 1200
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  const navigate = useNavigate()
  const location = useLocation();

  const sections = useDashboardSections();

  // const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <DashboardNavbarRoot {...other}>
      <Container maxWidth='lg'>
        <Toolbar sx={{ minHeight: 64 }}>
          <IconButton
            color="inherit"
            onClick={onSidebarMobileOpen}
            sx={{
              mr: 2,
              display: {
                sm: 'block',
                md: 'none',
                // lg: 'none'
              }
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box
            sx={{
              maxWidth: '150px',
              py: 1,
              zIndex: 2,
              '&:hover': {
                cursor: 'pointer'
              }
            }}
            onClick={() => navigate('/dashboard/overzicht')}
          >
            <Logo
              sx={{
                display: {
                  lg: 'inline',
                  xs: 'none'
                },
              }}
            />
          </Box>
          <Box flexGrow={1} />
          <Box display={{
            sm: 'none',
            md: 'block',
            // lg: 'block'
          }}>
          {sections.map((section) => (
            <NavSectionTop
              key={section.title}
              pathname={location.pathname}

              {...section}
            />
          ))}
          </Box>
          <Box sx={{ ml: 2 }}>
            <AccountPopover />
          </Box>
        </Toolbar>

      </Container>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
