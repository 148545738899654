export const amplifyConfig = {
  Auth: {
    identityPoolId: 'eu-west-1:bedc6caf-f5da-43d5-9482-eb16895969f7',
    region: 'eu-west-1',
    identityPoolRegion: 'eu-west-1',
    userPoolId: 'eu-west-1_rflpTiX2g',
    userPoolWebClientId: '19sblkdra116ii852jdulctnef'
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_AWS_S3_UPLOAD_BUCKET,
      region: 'eu-west-1'
    }
  }
};

export const isDev = process.env.NODE_ENV === 'development'

export const SERVER_URL = isDev
  ? 'http://localhost:3010/dev'
  : 'https://wma7hq2xs6.execute-api.eu-west-1.amazonaws.com/live'