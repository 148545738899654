import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material'
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import FolderOpenIcon from '../../icons/FolderOpen';
import ContactSupportRoundedIcon from '@mui/icons-material/ContactSupportRounded';
import BriefcaseIcon from '../../icons/Briefcase'
import InfoIcon from '@mui/icons-material/Info'
import StorefrontIcon  from '@mui/icons-material/Storefront';
import LinkIcon  from '@mui/icons-material/Link';

import useAuth from '../../hooks/useAuth';

export const sections = [
  {
    title: '',
    items: [
      {
        title: 'Overzicht',
        path: '/dashboard/overzicht',
        icon: <ChartSquareBarIcon fontSize="small" />
      },
      {
        title: 'Polissen',
        path: '/dashboard/polissen',
        icon: <FolderOpenIcon fontSize="small" />
      },
      {
        title: 'Schademeldingen',
        path: '/dashboard/schademeldingen',
        icon: <BriefcaseIcon fontSize="small" />
      },
      {
        title: 'Dealerinformatie',
        path: '/dashboard/dealerinformatie',
        icon: <InfoIcon fontSize="small" />
      },
      {
        title: 'Dealers',
        path: '/dashboard/resellers',
        icon: <StorefrontIcon fontSize="small" />,
        guardRole: 'Admin'
      },
      {
        title: 'Wachtrij',
        path: '/dashboard/queue',
        icon: <LinkIcon fontSize="small" />,
        guardRole: 'Admin'
      },
      {
        title: 'Contact',
        path: '/dashboard/contact',
        icon: <ContactSupportRoundedIcon fontSize="small" />
      },
    ]
  },
];

export const useDashboardSections = () => {
  const { user } = useAuth()
  const _sections = []

  for (let i = 0; i < sections.length; i++) {
    const section = {
      title: sections[i].title,
      items: sections[i].items.filter(item => {
        return !item.guardRole || item.guardRole === user?.role

      })
    }
    _sections.push(section)
  }

  return _sections
}

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const DashboardLayoutWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '83px',
  // [theme.breakpoints.up('lg')]: {
  //   paddingLeft: '280px'
  // }
}));

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const DashboardLayout = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  // const { user } = useAuth();

  // if (user?.role != 'Admin') {
  //   const index = sections[0].items?.findIndex(item => item.path === '/dashboard/resellers')
  //   if (index > 0) {
  //     sections[0].items.splice(index, 1)
  //   }
  // }

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar
        onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)}
      />
      <DashboardSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Container maxWidth='lg' sx={{ mt: 2, p: 3 }}>
              <Outlet />
            </Container>
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
