import React from 'react'
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Divider, Link, Typography } from '@mui/material';
import {
  LoginAmplify
} from '../../components/authentication/login';
import Logo from '../../components/Logo';
import useAuth from '../../hooks/useAuth';

const Login = () => {
  const { platform } = useAuth();

  return (
    <>
      <Helmet>
        <title>SalesPoint - Inloggen</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >

        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  mb: 2
                }}
                display='flex'
                justifyContent='center'
              >
                <Box sx={{ maxWidth: '250px '}}>
                <Logo />
                </Box>
              </Box>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <div>
                  <Typography
                    color="textSecondary"
                    variant="h6"
                  >
                    Inloggen op SalesPoint
                  </Typography>
                </div>

              </Box>
              <Box
                sx={{
                  flexGrow: 1
                }}
              >
                <LoginAmplify />
              </Box>
              <Divider sx={{ mb: 3 }} />
              <Link
                color="textSecondary"
                component={RouterLink}
                to="/authentication/register"
                variant="body2"
              >
                Nieuwe account aanmaken (tijdelijk)
              </Link>
              {platform === 'Amplify' && (
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  sx={{ mt: 1 }}
                  to="/authentication/password-recovery"
                  variant="body2"
                >
                  Wachtwoord vergeten
                </Link>
              )}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
