import React, { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import { Navigate } from 'react-router-dom'

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
)

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const PasswordChange = Loadable(lazy(() => import('./pages/authentication/PasswordChange')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const Polissen = Loadable(lazy(() => import('./pages/dashboard/Polissen')));
const Resellers = Loadable(lazy(() => import('./pages/dashboard/Resellers')));
const ResellerAanpassen = Loadable(lazy(() => import('./pages/dashboard/ResellerAanpassen')));
const ResellerToevoegen = Loadable(lazy(() => import('./pages/dashboard/ResellerToevoegen')));
const ResellerExport = Loadable(lazy(() => import('./pages/dashboard/ResellerExport')));

const DealerInformatie = Loadable(lazy(() => import('./pages/dashboard/DealerInformatie')));

const PolissenAanmaken = Loadable(lazy(() => import('./pages/dashboard/PolisAanmaken/index')));

const PolissenDetails = Loadable(lazy(() => import('./pages/dashboard/PolissenDetails')));
const PolissenDetailsEdit = Loadable(lazy(() => import('./pages/dashboard/PolissenDetailsEdit')))
const SchadeMeldingen = Loadable(lazy(() => import('./pages/dashboard/SchadeMeldingen')));

const SchadeMeldingenAanmaken = Loadable(lazy(() => import('./pages/dashboard/SchademeldingenAanmaken/index.js')));

const SchadeMeldingenDetails = Loadable(lazy(() => import('./pages/dashboard/SchadeMeldingenDetails')));
const SchadeMeldingenDetailsEdit = Loadable(lazy(() => import('./pages/dashboard/SchadeMeldingenDetailsEdit')));
const Contact = Loadable(lazy(() => import('./pages/dashboard/Contact')));


const AanvragenQueue = Loadable(lazy(() => import('./pages/dashboard/AanvragenQueue')));
const AanvragenQueueErrors = Loadable(lazy(() => import('./pages/dashboard/AanvragenQueueErrors')));
const AanvragenQueueDetail = Loadable(lazy(() => import('./pages/dashboard/AanvragenQueueDetail')));
const AanvragenQueueEdit = Loadable(lazy(() => import('./pages/dashboard/AanvragenQueueEdit')));

const Debug = Loadable(lazy(() => import('./pages/dashboard/Debug')));

const routes = [
{
  path: 'authentication',
  children: [
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      )
    },
    {
      path: 'login-unguarded',
      element: <Login />
    },
    {
      path: 'password-recovery',
      element: <PasswordRecovery />
    },
    {
      path: 'password-reset',
      element: <PasswordReset />
    },
    {
      path: 'password-change',
      element: <PasswordChange />
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      )
    },
    {
      path: 'register-unguarded',
      element: <Register />
    },
    {
      path: 'verify-code',
      element: <VerifyCode />
    }
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
      ),
    children: [
      {
        path: 'overzicht',
        element: <Overview />
      },
      {
        path: 'polissen',
        children: [
        {
          path: '',
          element: <Polissen />
        },
        {
          path: 'aanmaken/*',
          element: <PolissenAanmaken />
        },
        {
          path: `details/:id`,
          element: <PolissenDetails />
        },
        {
          path: `details/:id/edit`,
          element: <PolissenDetailsEdit />
        }
      ]
    },
    {
      path: 'resellers',
      children: [
        {
          path: '',
          element: <Resellers />,
        },
        {
          path: 'new',
          element: <ResellerToevoegen />,
        },
        {
          path: ':id',
          element: <ResellerAanpassen />,
        },
        {
          path: 'export/:type',
          element: <ResellerExport />,
        }
      ]
    },
    {
      path: 'schademeldingen',
      children: [
        {
          path: '',
          element: <SchadeMeldingen />
        },
        {
          path: 'aanmaken/*',
          element: <SchadeMeldingenAanmaken />,
        },
        {
          path: 'details/:id',
          element: <SchadeMeldingenDetails />
        },
        {
          path: 'details/:id/edit',
          element: <SchadeMeldingenDetailsEdit />
        }
      ]
    },
    {
      path: 'queue',
      children: [
        {
          path: '',
          element: <AanvragenQueue />
        },
        {
          path: 'errors',
          element: <AanvragenQueueErrors />
        },
        {
          path: 'details/:id',
          element: <AanvragenQueueDetail />
        },
        {
          path: 'edit/:id',
          element: <AanvragenQueueEdit />
        },
      ]
    },
    {
      path: 'dealerinformatie',
      element: <DealerInformatie />
    },
    {
      path: 'contact',
      element: <Contact />
    },
    {
      path: 'debug',
      element: <Debug />
    },
  ]
},
{
  path: '*',
  element: <Navigate
  to="/authentication/login"
  replace
  />
}
];

export default routes;
