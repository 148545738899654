import React from 'react'
import { Box } from '@mui/material'
import KingpolisLogo from '../assets/kingpolis-logo.png'

const Logo = (props) => {
  return <Box>
    <img src={KingpolisLogo} alt="Kingpolis" style={{
      width: '100%'
    }} />
  </Box>
}

export default Logo;
