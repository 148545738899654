
import React, { StrictMode } from 'react';
import 'nprogress/nprogress.css';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, useLocation } from 'react-router-dom';

// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import nlLocale from 'date-fns/locale/nl';
import { StyledEngineProvider } from '@mui/material/styles';
import App from './App';
import { AuthProvider } from './contexts/AmplifyContext';
import { SettingsProvider } from './contexts/SettingsContext';

const ScrollToTop = () => {
  const { pathname } = useLocation()

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const root = createRoot(document.getElementById('root'))

root.render(
  <StrictMode>
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nlLocale}>
          <SettingsProvider>
            <BrowserRouter>
              <ScrollToTop />
              <AuthProvider>
                <App />
              </AuthProvider>
            </BrowserRouter>
          </SettingsProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  </StrictMode>
);
